import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MyAccount from 'src/layouts/MyAccount';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/Loader';
import AuthGuard from 'src/components/Guards/AuthGuard';
import GuestGuard from 'src/components/Guards/GuestGuard';
import HomeView from 'src/views/home/HomeView';
import SettingsGuard from 'src/components/Guards/SetingsGuard';
import SettingsLayout from 'src/views/Test';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen open={true}/>}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/accessdenied',
    component: lazy(() => import('src/views/errors/AccessDenied'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/signUp',
    component: lazy(() => import('src/views/auth/LoginView/SignUp'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,

    path: '/reset_password',
    component: lazy(() => import('src/views/auth/LoginView/Reset_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot',
    component: lazy(() => import('src/views/auth/LoginView/Forgot_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirm',
    component: lazy(() =>
      import('src/views/auth/LoginView/Confirm_forgot_password')
    )
  },
  {
    path: '/app/admin/profile1',
    guard: AuthGuard,
    layout: MyAccount,
    routes: [
      {
        exact: true,
        path: '/app/admin/profile1',
        component: lazy(() => import('src/views/StudentProfile'))
      },
      {
        exact: true,
        path: '/app/admin/profile1/personaldetails',
        component: lazy(() => import('src/views/accountvieew'))
      },
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/personaldetails',
      //   component: lazy(() => import('src/views/profile2profile'))
      // },
    {
        exact: true,
        path: '/app/admin/profile1/security',
        component: lazy(() => import('src/views/Security'))
      },
      {
        exact: true,
        path: '/app/admin/profile1/Access',
        component: lazy(() => import('src/views/Access'))
      },
      {
        exact: true,
        path: '/app/admin/profile1/Access1',
        component: lazy(() => import('src/views/Access2'))
      },
      {
        exact: true,
        path: '/app/admin/profile1/Access2',
        component: lazy(() => import('src/views/Access3'))
      },
      {
        exact: true,
        path: '/app/admin/profile1/Access3',
        component: lazy(() => import('src/views/Access4'))
      },
      {
        exact: true,
        path: '/app/admin/profile1/Access4',
        component: lazy(() => import('src/views/Access5'))
      },
      {
        exact: true,
        path: '/app/admin/profile1/Access5',
        component: lazy(() => import('src/views/Access6'))
      },
    
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/wallet',
      //   component: lazy(() => import('src/views/profile5profile'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/company',
      //   component: lazy(() => import('src/views/profile7profile'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/polices',
      //   component: lazy(() => import('src/views/profile8profile'))
      // }, 
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/notification',
      //   component: lazy(() => import('src/views/profile10profile'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/notification',
      //   component: lazy(() => import('src/views/profile10profile'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/aboutus',
      //   component: lazy(() => import('src/views/profile10profile'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/faq',
      //   component: lazy(() => import('src/views/profile11profile'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/contactus',
      //   component: lazy(() => import('src/views/profile12profile'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/compliences',
      //   component: lazy(() => import('src/views/profile13profile'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/accountactivity',
      //   component: lazy(() => import('src/views/profile14profile'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/loginactivity',
      //   component: lazy(() => import('src/views/profile15profile'))
      // },
      
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/sessionactivity',
      //   component: lazy(() => import('src/views/profile16profile'))
      // },
      
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/recentactivity',
      //   component: lazy(() => import('src/views/profile17profile'))
      // },
      
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/groups',
      //   component: lazy(() => import('src/views/profile18profile'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/organization',
      //   component: lazy(() => import('src/views/profile19profile'))
      // },
      // {
      //   exact: true,
      //   path: '/login',
      //   component: lazy(() => import('src/views/profile20profile'))
      // },
      
      

      
      
      
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        // guard: SettingsGuard,
        path: '/app/payroll/home',
        component: lazy(() => import('src/views/Dashboard'))
      },
      
      {
        exact: true,
        // guard: SettingsGuard,
        path: '/app/payroll/home1',
        component: lazy(() => import('src/views/Dashboard/Dash'))
      },
      
      {
        exact: true,
        path: '/app/payroll/attendance',

        component: lazy(() => import('src/views/Attendance/List'))
      },
      {
        exact: true,
        path: '/app/payroll/attendancenew',

        component: lazy(() => import('src/views/AttendenceDesktop'))
      },
      {
        exact: true,
        path: '/app/payroll/profile',
        component: lazy(() => import('src/views/StudentProfile'))
      },
      {
        exact: true,
        path: '/app/payroll/profile/Experience',
        component: lazy(() => import('src/views/ExperienceEdit'))
      },
      {
        exact: true,
        path: '/app/payroll/profile/Education',
        component: lazy(() => import('src/views/EducationEdit'))
      },
      {
        exact: true,
        path: '/app/payroll/profile/TechnicalSkills',
        component: lazy(() => import('src/views/TechnicalSkillsEdit'))
      },
      {
        exact: true,
        path: '/app/payroll/profile/Projects',
        component: lazy(() => import('src/views/ProjectsEdit'))
      },
      // {
      //   exact: true,
      //   path: '/app/payroll/profile/Accomplishments',
      //   component: lazy(() => import('src/views/AccomplishmentsDetailsEdit'))
      // },
      {
        exact: true,
        path: '/app/payroll/profile/Social',
        component: lazy(() => import('src/views/StudentProfile/SocialEdit'))
      },
      {
        exact: true,
        path: '/app/payroll/profile/Languages',
        component: lazy(() => import('src/views/StudentProfile/LanguageEdit'))
      },
      // {
      //   exact: true,
      //   path: '/app/payroll/attendance',

      //   component: lazy(() => import('src/views/Attendance/ContractList'))
      // },
      // {
      //   exact: true,
      //   path: '/app/payroll/attendance/:id',
      //   component: lazy(() => import('src/views/Attendance/List'))
      // },
      {
        exact: true,
        path: '/app/payroll/calender',
        component: lazy(() => import('src/views/Calendar'))
      },
      {
        exact: true,
        path: '/app/payroll/facultyAttendance',
        component: lazy(() => import('src/views/MyPresence'))
        // component: lazy(() => import('src/views/FacultyAttendance'))
      },
      {
        exact: true,
        path: '/app/payroll/movements',
        component: lazy(() => import('src/views/MovementRegisterQrganizers'))
      },
      {
        exact: true,
        path: '/app/payroll/more',
        component: lazy(() => import('src/views/More'))
      },
      {
        exact: true,
        path: '/app/payroll/leaves',
        component: lazy(() => import('src/views/Leaves'))
      },
      {
        exact: true,
        path: '/app/payroll/payroll',
        component: lazy(() => import('src/views/MyPayroll'))
      },
      {
        exact: true,
        path: '/app/payroll/Asset',
        component: lazy(() => import('src/views/Assets'))
      },
      {
        exact: true,
        path: '/app/payroll/Benefit',
        component: lazy(() => import('src/views/Benifit'))
      },
      {
        exact: true,
        path: '/app/payroll/Document',
        component: lazy(() => import('src/views/Document'))
      },
      {
        exact: true,
        path: '/app/payroll/ExitInfo',
        component: lazy(() => import('src/views/ExitInfo'))
      },
      {
        exact: true,
        path: '/app/payroll/Organization',
        component: lazy(() => import('src/views/Organization'))
      },
      {
        exact: true,
        path: '/app/payroll/approvals',
        component: lazy(() => import('src/views/Approvals'))
      },
      {
        exact: true,
        path: '/app/payroll/inchargeHOD',
        component: lazy(() => import('src/views/InchargeHOD'))
      },
      // {
      //   exact: true,
      //   path: '/app/payroll/leaveApplications',
      //   component: lazy(() => import('src/views/LeaveApplications'))
      // },

      {
        exact: true,
        path: '/app/payroll/leaveApplications',
        component: lazy(() => import('src/views/LeaveApplications'))
      },


      {
        exact: true,
        path: '/app/payroll/movementRegister',
        component: lazy(() => import('src/views/MovementRegister'))
      },
      
      {
        exact: true,
        path: '/app/payroll/schedule',
        component: lazy(() => import('src/views/Schedule'))
      },
      {
        exact: true,
        path: '/app/payroll/schedule/:id/:status',
        component: lazy(() => import('src/views/Schedule/DraftScheduleFee'))
      },
      {
        exact: true,
        path: '/app/payroll/schedule/:id/:status/lop',
        component: lazy(() => import('src/views/Schedule/BulkUploadLOP'))
      },
      {
        exact: true,
        path: '/app/payroll/schedule/:id/:status/session',
        component: lazy(() => import('src/views/Schedule/BulkUploadSession'))
      },
      {
        exact: true,
        path: '/app/payroll/schedule/:id/:status/deductions',
        component: lazy(() => import('src/views/Schedule/BulkUploadDeductions'))
      },
      {
        exact: true,
        path: '/app/payroll/schedule/:id/:status/allowance',
        component: lazy(() => import('src/views/Schedule/BulkUploadAllowances'))
      },
      {
        exact: true,
        path: '/app/payroll/setup/attendance',
        component: lazy(() => import('src/views/AttendanceSetup'))
      },
      {
        exact: true,
        layout : SettingsLayout,
        path: '/app/payroll/setup/deptAdminSetup',
        component: lazy(() => import('src/views/DeptAdmin'))
      },
      {
        exact: true,
        path: '/app/payroll/attendanceEntry',
        component: lazy(() => import('src/views/AttendanceEnte'))
      },
      {
        exact: true,
        path: '/app/payroll/reports',
        component: lazy(() => import('src/views/Reports'))
      },
      {
        exact: true,
        path: '/app/payroll/bulkUploadAttendance',
        component: lazy(() => import('src/views/Attendance/BulkUploadAttendance'))
      },
      {
        exact: true,
        layout : SettingsLayout,
        path: '/app/payroll/ManageAttendance',
        component: lazy(() => import('src/views/ManageAttendance'))
      },
      {
        exact: true,
        layout : SettingsLayout,
        path: '/app/payroll/ManageAttendance/:id/:attendance_date',
        component: lazy(() => import('src/views/ManageAttendance/AttendanceLogs'))
      },
      {
        exact: true,
        path: '/app/admin/user_management',
        guard: SettingsGuard,
        component: lazy(() => import('src/views/UserManagement'))
      },

      // {
      //   exact: true,
      //   path: '/app/admin/Faculty',
      //   component: lazy(() => import('src/views/Faculty'))
      // },
      {
        exact: true,
        path: '/app/admin/Faculty/:page_id',
        component: lazy(() => import('src/views/Employee'))
      },
      {
        exact: true,
        path: '/app/payroll/Employee/:page_id',
        component: lazy(() => import('src/views/Employee'))
      },
      {
        exact: true,
        path: '/app/payroll/leaveApplications/:page_id',
        component: lazy(() => import('src/views/Employee'))
      },
      {
        exact: true,
        path: '/app/admin/leaveApplications/:page_id',
        component: lazy(() => import('src/views/Employee'))
      },
      {
        exact: true,
        path: '/app/admin/Faculty_Attendance/:page_id',
        component: lazy(() => import('src/views/Employee'))
      },
      {
        exact: true,
        path: '/app/admin/Employees/:page_id',
        component: lazy(() => import('src/views/Employee'))
        
      },
      {
        exact: true,
        path: '/app/admin/movement_register/:page_id',
        component: lazy(() => import('src/views/Employee'))
      },
      {
        exact: true,
        path: '/app/admin/Employees/movement',
        component: lazy(() => import('src/views/Faculty/EmployeeMovement'))
      },
      {
        exact: true,
        path: '/app/admin/Employees/attendance/attendence_view',
        component: lazy(() => import('src/views/Faculty/EmployeeAttendance'))
      },
      {
        exact: true,
        path: '/app/admin/Employees/leaves',
        component: lazy(() => import('src/views/Faculty/EmployeeLeave'))
      },
      {
        exact: true,
        path: '/app/admin/faculty/:id',
        component: lazy(() => import('src/views/Faculty/EmployeeDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/employee/addEmployee',
        component: lazy(() => import('src/views/Faculty/AddEmployee'))
      },
      {
        exact: true,
        path: '/app/admin/employee/addEmployee/:id',
        component: lazy(() => import('src/views/Faculty/AddEmployeeContinue'))
      },
      {
        exact: true,
        path: '/app/admin/employee/:id/:layout_id',
        component: lazy(() => import('src/views/CandidateDetails'))
      },
      {
        exact: true,
        path: '/app/payroll/employee/addEmployeeSalary/:id',
        component: lazy(() => import('src/views/Faculty/AddEmployeeSalary'))
      },
      {
        exact: true,
        path: '/app/edit/employeesalary/:id',
        component: lazy(() =>
          import('src/views/Faculty/EmployeeDetailView/SalaryEditView')
        )
      },
      {
        exact: true,
        path: '/app/revise/employeesalary/:id',
        component: lazy(() =>
          import('src/views/Faculty/EmployeeDetailView/SalaryEditView')
        )
      },
      {
        exact: true,
        path: '/app/admin/employee/:id',
        component: lazy(() => import('src/views/Faculty/EmployeeDetailView'))
      },
      {
        exact: true,
        path: '/app/reports/employeeSalaryStructure',
        component: lazy(() =>
          import('src/views/Reports/employeeSalaryStructure')
        )
      },
      {
        exact: true,
        path: '/app/reports/headwiseyearlyreport',
        component: lazy(() =>
          import('src/views/Reports/HeadwiseYearlyReport')
        )
      },
      {
        exact: true,
        path: '/app/reports/SalaryIncrementReport',
        component: lazy(() =>
          import('src/views/Reports/SalaryIncrementReport')
        )
      },
      {
        exact: true,
        path: '/app/reports/employeeListReport',
        component: lazy(() => import('src/views/Reports/EmployeeListReport'))
      },
      {
        exact: true,
        path: '/app/reports/employeeListReportDynamic',
        component: lazy(() =>
          import('src/views/Reports/EmployeeListReportDynamic')
        )
      },
      {
        exact: true,
        path: '/app/reports/ContractemployeeSalary',
        component: lazy(() =>
          import('src/views/Reports/ContractEmployeeSalary')
        )
      },
      {
        exact: true,
        path: '/app/reports/MonthlyLOP',
        component: lazy(() =>
          import('src/views/Reports/MonthlyLOPReport')
        )
      },
      {
        exact: true,
        path: '/app/reports/ContractemployeeSalaryBill',
        component: lazy(() =>
          import('src/views/Reports/ContractEmployeeSalaryBill')
        )
      },
      {
        exact: true,
        path: '/app/reports/employeeSalaryStructureDept',
        component: lazy(() =>
          import('src/views/Reports/employeeSalaryStructureDept')
        )
      },
      {
        exact: true,
        path: '/app/reports/customPaybill',
        component: lazy(() => import('src/views/Reports/customPaybill'))
      },
      {
        exact: true,
        path: '/app/reports/MonthWiseBill',
        component: lazy(() => import('src/views/Reports/MonthWiseBill'))
      },
      {
        exact: true,
        path: '/app/reports/MonthWiseBillGross',
        component: lazy(() => import('src/views/Reports/MonthWiseBillGross'))
      },
      {
        exact: true,
        path: '/app/reports/MonthWiseBillDept',
        component: lazy(() => import('src/views/Reports/MonthWiseBillDept'))
      },
      {
        exact: true,
        path: '/app/reports/MonthWiseBillCategory',
        component: lazy(() => import('src/views/Reports/MonthWiseBillCategory'))
      },
      {
        exact: true,
        path: '/app/reports/HeadWiseReport',
        component: lazy(() =>
          import('src/views/Reports/AllowanceDeductionReport')
        )
      },
      {
        exact: true,
        path: '/app/reports/salaryinformationreport',
        component: lazy(() =>
          import('src/views/Reports/SalaryInformationReport')
        )
      },
      {
        exact: true,
        path: '/app/reports/SalarySummaryReport',
        component: lazy(() => import('src/views/Reports/SalarySummaryReport'))
      },
      {
        exact: true,
        path: '/app/reports/ConsolidatedSalaryMBA',
        component: lazy(() => import('src/views/Reports/ConsolidatedSalaryMBA'))
      },
      {
        exact: true,
        path: '/app/reports/SalarySummaryMBA',
        component: lazy(() => import('src/views/Reports/SalarySummaryMBA'))
      },
      {
        exact: true,
        path: '/app/reports/CustomSalaryBill',
        component: lazy(() => import('src/views/Reports/CustomSalaryBill'))
      },
      {
        exact: true,
        path: '/app/reports/EmployeeSalaryDetails',
        component: lazy(() => import('src/views/Reports/EmployeeSalaryDetails'))
      },
      {
        exact: true,
        path: '/app/reports/PayBill',
        component: lazy(() => import('src/views/Reports/PayBillReport'))
      },
      {
        exact: true,
        path: '/app/reports/CustomBIETPayBill',
        component: lazy(() => import('src/views/Reports/CustomBIETPayBillReport'))
      },
      {
        exact: true,
        path: '/app/reports/EPF',
        component: lazy(() => import('src/views/Reports/EPFreport'))
      },
      {
        exact: true,
        path: '/app/reports/ESI',
        component: lazy(() => import('src/views/Reports/ESIreport'))
      },
      {
        exact: true,
        path: '/app/reports/PT',
        component: lazy(() => import('src/views/Reports/PTreport'))
      },
      {
        exact: true,
        path: '/app/reports/LIC',
        component: lazy(() => import('src/views/Reports/LICSummary'))
      },
      {
        exact: true,
        path: '/app/reports/Loan',
        component: lazy(() => import('src/views/Reports/LoanReport'))
      },
      {
        exact: true,
        path: '/app/reports/Tax',
        component: lazy(() => import('src/views/Reports/TaxReport'))
      },
      {
        exact: true,
        path: '/app/reports/Withheld',
        component: lazy(() => import('src/views/Reports/WithheldReport'))
      },
      {
        exact: true,
        path: '/app/reports/LOP',
        component: lazy(() => import('src/views/Reports/LOPreport'))
      },
      {
        exact: true,
        path: '/app/reports/attendance/timeSheet',
        component: lazy(() => import('src/views/Reports/TimeSheetReport'))
      },
      {
        exact: true,
        path: '/app/reports/attendance/emp_attendance',
        component: lazy(() =>
          import('src/views/Reports/EmployeeAttendanceReport')
        )
      },
      {
        exact: true,
        path: '/app/reports/attendance/movementRegister',
        component: lazy(() => import('src/views/Reports/MovementRegister'))
      },
      {
        exact: true,
        path: '/app/reports/attendance/dailyAttendanceReport',
        component: lazy(() => import('src/views/Reports/DailyAttendanceReport'))
      },
      {
        exact: true,
        path: '/app/reports/attendance/LeaveReport',
        component: lazy(() => import('src/views/Reports/LeaveReport'))
      },
      {
        exact: true,
        path: '/app/reports/attendance/EmployeeSummaryReport',
        component: lazy(() => import('src/views/Reports/EmployeeSummaryReport'))
      },
      {
        exact: true,
        path: '/app/reports/attendance/custom_timeSheet',
        component: lazy(() => import('src/views/Reports/CustomTimeSheetReport'))
      },
      {
        exact: true,
        path: '/app/reports/attendance/lateTimeReport',
        component: lazy(() => import('src/views/Reports/LateTimeReport'))
      },
      {
        exact: true,
        path: '/app/reports/attendance/DailyLeaveReport',
        component: lazy(() => import('src/views/Reports/DailyLeaveReport'))
      },
      {
        exact: true,
        path: '/app/reports/attendance/DailyMovementRegister',
        component: lazy(() => import('src/views/Reports/DailyMovementReport'))
      },
      {
        exact: true,
        path: '/app/reports/attendance/MonthlyLeaveReport',
        component: lazy(() => import('src/views/Reports/MonthlyLeaveReport'))
      },
      {
        exact: true,
        path: '/app/reports/attendance/LeaveBalance',
        component: lazy(() => import('src/views/Reports/LeaveBalance'))
      },
      {
        exact: true,
        path: '/app/reports/attendance/datewise_emp_attendance',
        component: lazy(() =>
          import('src/views/Reports/DateWiseAttendanceMonthlyReport')
        )
      },
      {
        exact: true,
        path: '/app/reports/attendance/monthly',
        component: lazy(() =>
          import('src/views/Reports/AttendanceMonthlyReport')
        )
      },
      {
        exact: true,
        path: '/app/reports/attendance/monthly_contract',
        component: lazy(() =>
          import('src/views/Reports/AttendanceMonthlyReportContract')
        )
      },
      {
        exact: true,
        path: '/app/reports/attendance/monthly_contract_detail',
        component: lazy(() =>
          import('src/views/Reports/AttendanceMonthlyReportContractDetail')
        )
      },
      {
        exact: true,
        path: '/app/reports/BankReportMBA',
        component: lazy(() => import('src/views/Reports/BankReportMBA'))
      },
      {
        exact: true,
        path: '/app/reports/BillAck',
        component: lazy(() => import('src/views/Reports/BillAck'))
      },
      {
        exact: true,
        path: '/app/reports/EmployeeCountReport',
        component: lazy(() => import('src/views/Reports/EmployeeCountReport'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/programs',
        component: lazy(() => import('src/views/InstSetup/Programs'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/quota',
        component: lazy(() => import('src/views/InstSetup/Quota'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/stream',
        component: lazy(() => import('src/views/InstSetup/Stream'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/stream/addStream',
        component: lazy(() => import('src/views/InstSetup/Stream/AddStream'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/stream/:id',
        component: lazy(() => import('src/views/InstSetup/Stream/Details'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/stream/:id/edit',
        component: lazy(() => import('src/views/InstSetup/Stream/StreamEditView'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/batch',
        component: lazy(() => import('src/views/InstSetup/Batch'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/batch/addBatch',
        component: lazy(() => import('src/views/InstSetup/Batch/AddBatch'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/batch/:id',
        component: lazy(() => import('src/views/InstSetup/Batch/BatchDetailView'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/batch/:id/edit',
        component: lazy(() => import('src/views/InstSetup/Batch/BatchEditView'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/academicyear',
        component: lazy(() => import('src/views/InstSetup/AcademicYear'))
      },
      {
        layout : SettingsLayout,
        exact: true,
        path: '/app/setting/usermanagement/enabledsoon/:page_type',
        component: lazy(() => import('src/views/EnableSoon'))
      },
      {
        layout : SettingsLayout,
        exact: true,
        path: '/app/setting/usermanagement/applicationsNew',
        component: lazy(() => import('src/views/ApplicationsNew'))
      },
      {
        layout : SettingsLayout,
        exact: true,
        path: '/app/setting/usermanagement/adminsNew',
        component: lazy(() => import('src/views/UsersNew'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/admin/templates/settings/EmailTemplate',
        component: lazy(() => import('src/views/InstSetup/EmailTemplate'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/admin/settings/LeaveType',
        component: lazy(() => import('src/views/InstSetup/LeaveType'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/admin/settings/hrms_department',
        component: lazy(() => import('src/views/InstSetup/HRMSDepartment'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/admin/settings/hrms_designation',
        component: lazy(() => import('src/views/InstSetup/HRMSDesignation'))
      },
      
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/GeneralSettings/Company',
        component: lazy(() => import('src/views/InstitutionNew'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/Usermanagement/Users/UsersDetails',
        component: lazy(() => import('src/views/InstSetup/Users'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/Integration/IntegrationDetails',
        component: lazy(() => import('src/views/InstSetup/Integrations/Integration'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/GeneralSettings/Personal',
        component: lazy(() => import('src/views/PersonalSetup'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/Integration/IntegrationDetails',
        component: lazy(() => import('src/views/Integration'))
      },
      {
        exact: true,
        guard: SettingsGuard,
        layout : SettingsLayout,

        path: '/app/setting/usermanagement/user_management',
        component: lazy(() => import('src/views/UserManagement'))
      },
    ]
  },

  {
    path: '*',
    // layout: MainLayout,
    routes: [
      {
        exact: true,
        guard: GuestGuard,
        path: '/',
        component: lazy(() => import('src/views/auth/LoginView/JWTLogin'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
